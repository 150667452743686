// src/components/FloatingToggleButton.js
import React from 'react';
import './FloatingToggleButton.css';

function FloatingToggleButton({ isExpanded, onToggle }) {
  return (
    <div className="floating-toggle-button" onClick={onToggle}>
      <img
        src={isExpanded ? '/imgs/collapse50.png' : '/imgs/expand50.png'}
        alt={isExpanded ? 'Collapse All' : 'Expand All'}
        className="toggle-icon"
      />
    </div>
  );
}

export default FloatingToggleButton;
